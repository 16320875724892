<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <template v-slot:heading>
            <h1 class='display-2'>Monitoring Aktivitas Pegawai {{namaPegawai}}</h1>
          </template>
          <v-toolbar flat>
            <div class="mt-5">
              <v-row>
                <v-col cols="2" class="mt-2">
                  Filter
                </v-col>
                <v-col cols="10" class="d-flex">
                  <v-checkbox v-model="filter.diterima" class="mt-2" label="Diterima"></v-checkbox>
                  <v-checkbox v-model="filter.belum_validasi" label="Belum Validasi"></v-checkbox>
                  <v-checkbox v-model="filter.tolak_atasan" label="Ditolak Atasan"></v-checkbox>
                  <v-checkbox v-model="filter.tolak_admin" label="Ditolak Admin"></v-checkbox>
                  <v-checkbox v-model="filter.hapus" class="mt-2" label="Dihapus"></v-checkbox>
                </v-col>
              </v-row>
            </div>
            <div class="mt-7 ml-16">
              <v-row>
                <v-col cols="4">
                  <v-menu
                    v-model="fromDateMenu"
                    :close-on-content-click="false"
                    min-width="100px"
                    lazy offset-y full-width>
                    <template v-slot:activator="{ on }">
                      <v-text-field label="Tanggal Mulai" append-icon="mdi-calendar" outlined dense readonly v-model="fromDateVal" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker
                    locale="in-ID"
                    v-model="fromDateVal"
                    style="margin-top:0px"
                    no-title
                    @input="fromDateMenu = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <v-menu
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    min-width="100px"
                    transition="scale-transition"
                    lazy offset-y full-width>
                    <template v-slot:activator="{ on }">
                      <v-text-field label="Tanggal Akhir" append-icon="mdi-calendar" outlined dense readonly v-model="endDateVal" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker
                    locale="in-ID"
                    v-model="endDateVal"
                    style="margin-top:0px"
                    no-title
                    @input="endDateMenu = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="2" class="d-flex">
                  <v-btn small color="primary" @click="cari"><v-icon small>mdi-magnify</v-icon> Cari</v-btn>&ensp;
                  <v-btn small color="primary" @click="exportMonitoringPegawai()"><v-icon small>mdi-file-excel</v-icon> &nbsp;Export</v-btn>
                </v-col>
                <!-- <v-col cols="2">
                  <v-btn small color="primary" @click="exportMonitoringPegawai()"><v-icon small>mdi-file-excel</v-icon> &nbsp;Export</v-btn>
                </v-col> -->
              </v-row>
            </div>
            <v-spacer></v-spacer>
          </v-toolbar>
          <!-- <LembarMonitoringAktivitasPribadi :dataLembar="items" :filter="filter" :awal="fromDateVal" :akhir="endDateVal"></LembarMonitoringAktivitasPribadi> -->
          <div class="row mb-2" style="padding: 10px; border: 1px solid #333; background-color: white;margin-top:20px;width:100%;margin-left:2px" v-if="show">
            <v-simple-table width="100%" ref="tableLembarMonitoring">
              <tr>
                <td colspan="3" style="border-bottom: 1px solid #333; text-align: center;">
                <h3><b>MONITORING AKTIVITAS PRIBADI PADA BULAN {{parseMonth(items.bulan)}}</b></h3></td>
              </tr>
              <tR><td>&nbsp;</td></tR>
              <tr><td width="100px">NIP</td><td width="1px">:</td><td> &emsp;{{items.peg_nip}}</td></tr>
              <tr><td width="100px">NAMA</td><td width="1px">:</td><td> &emsp;{{items.peg_nama}}</td></tr>
              <tr><td width="100px">FOTO</td><td width="1px">:</td><td> &emsp;<v-avatar class="square" tile size="120"> <v-img :src="items.avatarPhoto"></v-img></v-avatar></td></tr>
              <tr><td width="100px">SKPD</td><td width="1px">:</td><td> &emsp;{{jabatan.kolok}}</td></tr>
              <tr><td width="100px">JABATAN</td><td width="1px">:</td><td> &emsp;{{jabatan.jabatan}}</td></tr>
              <tr><td width="100px">LEVEL </td><td width="1px">:</td><td> &emsp;{{ capaian.jenis == 1 ? 'Staf' : (capaian.jenis == 2 ? 'Struktural Atasan' : (capaian.jenis == 3 ? 'Struktural Kepala' : '-')) }}</td></tr>	
              <tr>
                <td colspan="3" style="border-bottom: 1px solid #333">&nbsp;</td>
              </tr>
              <tr><td colspan="3">
              <br>
              <h4>MONITORING AKTIVITAS UTAMA BULAN {{parseMonth(items.bulan)}}</h4>
              <v-simple-table class="table table-bordered" width="100%">
                <thead>
                <tr>
                  <th>AKTIVITAS</th>
                  <th>
                      FREKUENSI LAPOR
                  </th>
                  <th>
                      TOTAL VOLUME
                  </th>
                  <th>
                    BOBOT
                  </th>
                  <th>
                    SATUAN MENIT
                  </th>
                  <th>
                    TOTAL MENIT
                  </th>
                </tr>
                </thead>
                <tbody>
                  <tr v-for="(a, i) in aktivitas" v-bind:key="i">
                    <td>{{a.nama_aktifitas}}
                    </td>
                    <td>{{a.frekuensi}}</td>
                    <td>{{a.total_volume}} {{ a.angka ? 'x' : '' }} {{a.satuan_output}}</td>
                    <td>{{a.tingkat_kesulitan == null ? '-' : a.tingkat_kesulitan}}</td>
                    <td>{{a.waktu}} Menit</td>
                    <td>{{a.total_menit}} Menit</td>
                  </tr>
                </tbody>
              </v-simple-table>
              </td></tr>
              <tr><td colspan="3">
                <br>
                <h4>MONITORING AKTIVITAS TAMBAHAN BULAN {{parseMonth(items.bulan)}}</h4>
                <v-simple-table class="table table-bordered" width="100%">
                  <thead>
                    <tr>
                      <th>AKTIVITAS</th>
                      <th>
                          FREKUENSI LAPOR
                      </th>
                      <th>
                          TOTAL VOLUME
                      </th>
                      <th>
                        BOBOT
                      </th>
                      <th>
                        SATUAN MENIT
                      </th>
                      <th>
                        TOTAL MENIT
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(a, i) in aktivitas_tambahan" v-bind:key="i">
                      <td>{{a.nama_aktifitas}}
                      </td>
                      <td>{{a.frekuensi}}</td>
                      <td>{{a.total_volume}} {{ a.angka ? 'x' : '' }} {{a.satuan_output}}</td>
                      <td>{{a.tingkat_kesulitan == null ? '-' : a.tingkat_kesulitan}}</td>
                      <td>{{a.waktu}} Menit</td>
                      <td>{{a.total_menit}} Menit</td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <br><br>
              </td></tr>
              <tr><td colspan="3">
                <h4>LIST AKTIVITAS UTAMA DISETUJUI BULAN {{parseMonth(items.bulan)}}</h4>
                <v-simple-table class="table table-bordered" width="100%">
                  <thead>
                      <tr>
                        <th>WAKTU</th>
                        <th>
                          AKTIVITAS
                        </th>
                        <th>
                          WAKTU - VOLUME
                        </th>
                        
                        <th>
                          NILAI
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="setuju.length > 0">
                        <template v-for="(s, i) in setuju">
                          <tr v-bind:key="i">
                            <td colspan="4">{{s.tanggal}}</td>
                          </tr>

                          <tr v-for="(is, ii) in s.isi_aktifitas" v-bind:key="ii">
                            <td>{{is.jam_mulai | paddingNumberClock}}:{{is.menit_mulai | paddingNumberClock}}&nbsp;-&nbsp;{{is.jam_selesai | paddingNumberClock}}:{{is.menit_selesai | paddingNumberClock}}</td>
                            <td><b>{{is.aktivitas.nama_aktifitas}}</b><br>{{is.keterangan}}
                              <br>
                              <b>Status: <font :color="is.status == null ? 'navy' : is.status == 'valid' ? 'green' : 'red'">{{is.status == null ? 'belum divalidasi' : is.status == 'valid' ? 'aktivitas diterima' : 'aktivitas ditolak'}}</font> </b>
                              <br>
                              <br>
                              <v-btn small color="yellow" @click="edit(is)" v-if="uname == '198508272003122001'" class="btn btn-info btn-xs"><span class="fa fa-edit"></span> Edit</v-btn>
                              <v-btn small color="red white--text" @click="tolak(is)" v-if="uname == '198508272003122001'" class="btn btn-danger btn-xs"><span class="fa fa-trash-o"></span> Hapus</v-btn>
                              <v-btn small color="primary" @click="openDialogValidasi(is)" class="btn btn-success btn-xs"><span class="fa fa-check"></span> Validasi</v-btn>
                            </td>
                            <td>@{{is.aktivitas.waktu}} X <span v-if="is.aktivitas.tingkat_kesulitan != 1">{{is.aktivitas.tingkat_kesulitan}} X</span> {{is.volume}} {{ is.angka ? 'x' : '' }} {{is.aktivitas.satuan_output}}</td>
                            <td>{{is.total}}</td>
                          </tr>
                          <tr v-bind:key="i"><td colspan="3" style="text-align: right;font-weight: bold;">TOTAL PER HARI :  </td><td>{{s.total_hari == "0" ? s.total : s.total_hari}}</td></tr>
                        </template>
                        <template v-if="total > 0">
                          <tr><td colspan="3" style="text-align: right;font-weight: bold;">TOTAL :  </td><td>{{total}}</td></tr>
                        </template>
                      </template>
                      <template v-else>
                        <tr>
                          <td colspan="4">Tidak Ada Data Ditemukan</td>
                        </tr>
                      </template>
                    </tbody>
                  </v-simple-table>
              </td></tr>
              <tr><td colspan="3">
                <h4>LIST AKTIVITAS TAMBAHAN DISETUJUI BULAN {{parseMonth(items.bulan)}}</h4>
                <v-simple-table class="table table-bordered" width="100%">
                  <thead>
                      <tr>
                        <th>WAKTU</th>
                        <th>
                            AKTIVITAS
                        </th>
                        <th>
                            WAKTU - VOLUME
                        </th>
                        
                        <th>
                          NILAI
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="setuju_tambahan.length > 0">
                        <template v-for="(s, i) in setuju_tambahan">
                          <tr v-bind:key="i">
                            <td colspan="4">{{s.tanggal}}</td>
                          </tr>

                          <tr v-for="(is, ii) in s.isi_aktifitas" v-bind:key="ii">
                            <td>{{is.jam_mulai | paddingNumberClock}}:{{is.menit_mulai | paddingNumberClock}}&nbsp;-&nbsp;{{is.jam_selesai | paddingNumberClock}}:{{is.menit_selesai | paddingNumberClock}}</td>
                            <td><b>{{is.aktivitas.nama_aktifitas}}</b><br>{{is.keterangan}}
                              <br>
                              <v-btn small color="yellow" @click="edit(is)" v-if="uname == '198508272003122001'" class="btn btn-info btn-xs"><span class="fa fa-edit"></span> Edit</v-btn>
                              <v-btn small color="red white--text" @click="tolak(is)" v-if="uname == '198508272003122001'" class="btn btn-danger btn-xs"><span class="fa fa-trash-o"></span> Hapus</v-btn>
                              <v-btn small color="primary" @click="openDialogValidasi(is)" class="btn btn-success btn-xs"><span class="fa fa-check"></span> Validasi</v-btn>
                            </td>
                            <td>@{{is.aktivitas.waktu}} X <span v-if="is.aktivitas.tingkat_kesulitan != 1">{{is.aktivitas.tingkat_kesulitan}} X</span> {{is.volume}} {{ is.angka ? 'x' : '' }} {{is.aktivitas.satuan_output}}</td>
                            <td>{{is.total}}</td>
                          </tr>
                          <tr v-bind:key="i"><td colspan="3" style="text-align: right;font-weight: bold;">TOTAL PER HARI :  </td><td>{{s.total_hari}}</td></tr>
                        </template>
                        <template v-if="total > 0">
                          <tr><td colspan="3" style="text-align: right;font-weight: bold;">TOTAL :  </td><td>{{total}}</td></tr>
                        </template>
                      </template>
                      <template v-else>
                        <tr>
                          <td colspan="4">Tidak Ada Data Ditemukan</td>
                        </tr>
                      </template>
                    </tbody>
                  </v-simple-table>
              </td></tr>
            </v-simple-table>
          </div>
          <div v-else align="center">
            <v-row class="mt-5" v-if="loading">
              <v-col>
                <v-skeleton-loader
                class="mx-auto"
                min-width="200"
                type="table"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row class="mt-5" v-else>
              <v-col>
                Belum ada data
              </v-col>
            </v-row>
          </div>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogValidasiAktivitas" max-width="600px">
      <v-card>
        <v-card-title>
          <h2>Validasi Aktivitas</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <b>Nama Aktivitas</b>
            </v-col>
            <v-col cols="9">
              <b>{{item.nama_aktifitas}}</b>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <b>Volume</b>
            </v-col>
            <v-col cols="1">
              <b>{{item.volume}}</b>
            </v-col>
            <v-col cols="3">
              <b>{{item.satuan_output}}</b>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loadingBtnValidasiTerima" :disable="disableBtnValidasiTerima" color="green" dark @click="validasi(item,'valid')">Terima</v-btn>
          <v-btn :loading="loadingBtnValidasiTolak" :disable="disableBtnValidasiTolak" color="red white--text" @click="validasi(item,'invalid')">Tolak</v-btn>
          <v-btn color="white" @click="refresh('validasi')">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditAktivitas" max-width="600px">
      <v-card>
        <v-card-title>
          <h2>Edit Aktivitas</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <b>Nama Aktivitas</b>
            </v-col>
            <v-col cols="9">
              <b>{{item.nama_aktifitas}}</b>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <b>Volume</b>
            </v-col>
            <v-col cols="2">
              <v-text-field dense v-model="item.volume" outlined></v-text-field>
            </v-col>
            <v-col cols="3">
              <b>{{item.satuan_output}}</b>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" dark @click="simpan(item)">Simpan</v-btn>
          <v-btn color="white" @click="refresh('edit')">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogHapusAktivitas" max-width="600px">
      <v-card>
        <v-card-title>
          <h2>Tolak/Hapus Aktivitas</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <b>Apakah anda yakin akan menghapus aktivitas ini?</b>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <b>Nama Aktivitas</b>
            </v-col>
            <v-col cols="9">
              <b>{{item.nama_aktifitas}}</b>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <b>Volume</b>
            </v-col>
            <v-col cols="1">
              <b>{{item.volume}}</b>
            </v-col>
            <v-col cols="3">
              <b>{{item.satuan_output}}</b>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red white--text" dark @click="hapus(item)">Hapus</v-btn>
          <v-btn color="white" @click="refresh('hapus')">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash'
import MonitoringAktivitasService from '@/services/MonitoringAktivitasService'
import store from '@/store'
import _g from '../../global'
import {local} from '@/store/local'
// import LembarMonitoringAktivitasPribadi from './components/LembarMonitoringAktivitasPribadi.vue'

export default {
  components:{
    // LembarMonitoringAktivitasPribadi
  },

  props:{
    dataLembar:Array,
  },

  data(){
    return{
      forceUpdate:false,
      loading:false,
      detailData:{},

      headers:[
        {value:'nama'},
        {value:'jabatan'},
        {value:'satuan_kerja'},
        {value:'jumlah_aktivitas'},
        {value:'utama_jumlah_aktivitas'},
        {value:'utama_total_menit'},
        {value:'tambahan_jumlah_aktivitas'},
        {value:'tambahan_total_menit'},
        {value:'jumlah_sudah_review'},
        {value:'jumlah_review_seharusnya'}
      ],
      items:[],

      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        enableDetail:false,
        enableCreate:false,
      },
      rowPerPage:0,
      page:1,
      totalKeseluruhan:0,
      selectSKPD:'',
      skpd:[],
      search: '',
      dialogLihatSKP: false,
      dataSKP: [],
      loadingLihatSKP: false,
      nilai_total_capaian: null,
      selected: {},
      tahun: null,
      bulanList:[
        { b:'Januari',k:1},
        { b:'Febuari',k:2},
        { b:'Maret',k:3},
        { b:'April', k:4},
        { b:'Mei', k:5},
        { b:'Juni', k:6},
        { b:'Juli', k:7},
        { b:'Agustus', k:8},
        { b:'September', k:9},
        { b:'Oktober', k:10},
        { b:'November', k:11},
        { b:'Desember', k:12},
      ],
      selectBulan:null,
      fromDateMenu: false,
      fromDateVal: null,
      endDateMenu: false,
      endDateVal: null,
      namaPegawai: '',
      filter: {
        'diterima': false,
        'belum_validasi': false,
        'tolak_atasan': false,
        'tolak_admin': false,
        'hapus': false,
      },
      jabatan : [],
      capaian : [],
      aktivitas : [],
      aktivitas_tambahan : [],
      setuju : [],
      setuju_tambahan : [],
      uname : [],
      total : [],
      total_tambahan : [],
      show: true,
      month: 0,
      dialogValidasiAktivitas: false,
      dialogEditAktivitas: false,
      dialogHapusAktivitas: false,
      item: {},
      loadingBtnValidasiTerima: false,
      loadingBtnValidasiTolak: false,
      disableBtnValidasiTerima: false,
      disableBtnValidasiTolak: false
    }
  },

  computed: {

  },

  watch:{

  },

  mounted(){

  },

  created(){
    var param = local.getLocal('ma_pegawai')
    this.namaPegawai = param.peg_nama + ' (' + param.peg_nip + ')'
    param.awal = this.fromDateVal
    param.akhir = this.endDateVal
    param.filter = this.filter
    this.month = param.bulan
    this.update(param)
    let current = store.state.user.current
    let user = store.state.user
    if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
      current.username = _g.overrideWithPLT(user.current.role)
    }else{
      current.username = user.current.username
    }
    this.uname = current.username
  },

  methods:{
    update(param){
      this.show = false
      this.loading = true
      MonitoringAktivitasService.getDataAktivitasPegawai({
        nip:param.peg_nip,
        bulan:param.bulan,
        awal:param.awal,
        akhir:param.akhir,
        filter:param.filter})
      .then((response)=>{
        var data = response.data.data
        data.pegawai.avatarPhoto = process.env.VUE_APP_IMG_URL + data.pegawai.peg_foto
        console.log(data)
        this.items = data.pegawai;
        this.jabatan = data.jabatan;
        this.capaian = data.penilaian;
        this.aktivitas = data.aktivitas;
        this.aktivitas_tambahan = data.aktivitas_tambahan;
        this.setuju = data.setujui;
        this.setuju_tambahan = data.setujui_tambahan;
        // this.uname = data.uname;
        for (var i = 0; i < data.setujui.length; i++) {
          this.total = data.setujui[i].total;
        }
        for (var a = 0; a < data.setujui_tambahan.length; a++) {
          this.total_tambahan = data.setujui_tambahan[a].total;
        }
        this.show = true
        this.loading = false
      }).finally(()=>{
        this.loading = false
      })
    },
    cari(){
      var awal = this.fromDateVal
      var akhir = this.endDateVal
      this.show = false
      this.loading = true
      MonitoringAktivitasService.getDataAktivitasPegawai({
        nip:this.items.peg_nip,
        bulan:this.month,
        awal:awal,
        akhir:akhir,
        filter:this.filter})
      .then((response)=>{
        var data = response.data.data
        data.pegawai.avatarPhoto = process.env.VUE_APP_IMG_URL + data.pegawai.peg_foto
        console.log(data)
        this.items = data.pegawai;
        this.jabatan = data.jabatan;
        this.capaian = data.penilaian;
        this.aktivitas = data.aktivitas;
        this.aktivitas_tambahan = data.aktivitas_tambahan;
        this.setuju = data.setujui;
        this.setuju_tambahan = data.setujui_tambahan;
        // this.uname = data.uname;
        for (var i = 0; i < data.setujui.length; i++) {
          this.total = data.setujui[i].total;
        }
        for (var a = 0; a < data.setujui_tambahan.length; a++) {
          this.total_tambahan = data.setujui_tambahan[a].total;
        }
        this.show = true
        this.loading = false
        var d = new Date(this.fromDateVal)
        this.items.bulan = (d.getMonth()+1)
      }).finally(()=>{
        this.show = true
        this.loading = false
      })
    },
    openDialogValidasi(e){
      this.item = e
      this.item.nama_aktifitas = this.item.aktivitas.nama_aktifitas
      this.item.satuan_output = this.item.aktivitas.satuan_output
      this.dialogValidasiAktivitas = true
    },
    validasi(e, v){
      if(v == 'valid'){
        this.loadingBtnValidasiTerima = true
        this.disableBtnValidasiTerima = true
      }else{
        this.loadingBtnValidasiTolak = true
        this.disableBtnValidasiTolak = true
      }
      MonitoringAktivitasService.validasiData({
        id:e.id,
        valid:v.toString(),
        nip:e.nip18})
      .then((response)=>{
        let res = response.data
        if (res.success){
            store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
        this.reloadTable()
        this.dialogValidasiAktivitas = false
      }).finally(()=>{
        if(v == 'valid'){
          this.loadingBtnValidasiTerima = false
          this.disableBtnValidasiTerima = false
        }else{
          this.loadingBtnValidasiTolak = false
          this.disableBtnValidasiTolak = false
        }
        this.dialogValidasiAktivitas = false
      })
    },
    edit(e){
      this.item = e
      this.item.nama_aktifitas = this.item.aktivitas.nama_aktifitas
      this.item.satuan_output = this.item.aktivitas.satuan_output
      this.dialogEditAktivitas = true
    },
    simpan(e){
      MonitoringAktivitasService.editData({
        id:e.id,
        volume:e.volume,
        nip:e.nip18})
      .then((response)=>{
        let res = response.data
        if (res.success){
            store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
        this.dialogEditAktivitas = false
      }).finally(()=>{
        this.dialogEditAktivitas = false
      })
    },
    tolak(e){
      this.item = e
      this.item.nama_aktifitas = this.item.aktivitas.nama_aktifitas
      this.item.satuan_output = this.item.aktivitas.satuan_output
      this.dialogHapusAktivitas = true
    },
    hapus(e){
      MonitoringAktivitasService.hapusData({id:e.id})
      .then((response)=>{
        let res = response.data
        if (res.success){
            store.commit('snackbar/setSnack',{message: res.message, color:'success'})
            this.reloadTable()
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
        this.dialogHapusAktivitas = false
      }).finally(()=>{
        this.dialogHapusAktivitas = false
      })
    },
    refresh(e){
      if(e == 'validasi'){
        this.dialogValidasiAktivitas = false
      }
      if(e == 'edit'){
        this.dialogEditAktivitas = false
      }
      if(e == 'hapus'){
        this.dialogHapusAktivitas = false
      }
      var param = local.getLocal('ma_pegawai')
      this.namaPegawai = param.peg_nama + ' (' + param.peg_nip + ')'
      param.awal = this.fromDateVal
      param.akhir = this.endDateVal
      param.filter = this.filter
      this.month = param.bulan
      this.update(param)
    },
    reloadTable(){
      var param = local.getLocal('ma_pegawai')
      this.namaPegawai = param.peg_nama + ' (' + param.peg_nip + ')'
      param.awal = this.fromDateVal
      param.akhir = this.endDateVal
      param.filter = this.filter
      this.month = param.bulan
      this.update(param)
    },
    exportMonitoringPegawai(){
      var current = this.$store.getters["user/current"]
      // var nip = current.username //nip
      var year = current.year
      // var urls = ""
      var base = process.env.VUE_APP_API_URL
      if(base === 'https://mangbagja-demo.rev.web.id/'){
        base = 'https://erk-demo.rev.web.id/'
      }else{
        base = 'https://kinerja.bandung.go.id/'
      }
      var param = local.getLocal('ma_pegawai')
      // this.namaPegawai = param.peg_nama + ' (' + param.peg_nip + ')'
      param.awal = this.fromDateVal
      param.akhir = this.endDateVal
      param.filter = this.filter
      let bulan = ''
      if(this.fromDateVal){
        var d = new Date(this.fromDateVal)
        bulan = (d.getMonth()+1)
      }
      if(bulan != ''){
        this.month = bulan
      }else{
        this.month = param.bulan
        var awal = new Date(year, (this.month-1), 1)
        var akhir = new Date(year, this.month, 0)
        param.awal = awal.getFullYear() + '-' + ('0' + (awal.getMonth()+1)).slice(-2) + '-' + ('0' + awal.getDate()).slice(-2)
        param.akhir = akhir.getFullYear() + '-' + ('0' + (awal.getMonth()+1)).slice(-2) + '-' + ('0' + akhir.getDate()).slice(-2)
      }
      this.month = bulan != '' ? bulan : param.bulan
      var filter = ''
      if(this.filter.diterima){
        filter += 'diterima,'
      }
      if(this.filter.belum_validasi){
        filter += 'belum_validasi,'
      }
      if(this.filter.tolak_atasan){
        filter += 'tolak_atasan,'
      }
      if(this.filter.tolak_admin){
        filter += 'tolak_admin,'
      }
      if(this.filter.hapus){
        filter += 'hapus'
      }
      filter = filter.replace(/,\s*$/, "");
      let urls = base+year+"/api/v1/statistik-aktivitas-perorang/export?nip="+param.peg_nip+"&bulan="+this.month+"&awal="+param.awal+"&akhir="+param.akhir+"&api=1&excel=1&filter="+filter;
      window.open(urls,'_blank');
    },
    parseMonth(e){
      if(Number.isInteger(e)){
        const bulan = ['JANUARI','FEBRUARI','MARET','APRIL','MEI','JUNI','JULI','AGUSTUS','SEPTEMBER','OKTOBER','NOVEMBER','DESEMBER']
        return bulan[(e-1)]
      }else{
        return e
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.pegawai-table{
  tr,th,td {
    border: 1px solid #ddd;
  }
  thead tr th{
    color:white;
  }
}
</style>
